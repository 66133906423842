<template>
    <section class="organizers container-fluid h-100 p-3">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto">
                <div class="position-sticky title">
                    <div class="d-flex flex-nowrap justify-content-between align-items-end">
                        <div class="d-flex flex-nowrap align-items-center">
                            <div class="d-flex d-xl-none align-items-center">
                                <button-toggle-side-bar></button-toggle-side-bar>
                            </div>
                            <h2 class="pl-3 pl-xl-0 mb-0 mr-2">
                            <span class="badge badge-dark">
                                {{ organizers.length }}
                            </span>
                            </h2>
                            <div class="d-flex align-items-end">
                                <h1 class="font-weight-bolder flex-grow-1 mb-0">
                                    Organizers
                                </h1>
                            </div>
                            <ul class="nav align-self-end">
                                <li class="nav-item">
                                    <router-link class="nav-link py-1"
                                                 v-bind:to="`/reviews`">
                                        Reviews
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="d-flex flex-nowrap">
                            <region-select class="mr-2"></region-select>
                        </div>
                    </div>
                    <div class="py-3 d-flex flex-nowrap justify-content-between">
                        <search-bar class="search-bar flex-grow-1 mb-2 mr-2"
                                    v-model="keywords">
                        </search-bar>
                        <div class="d-flex align-items-center">
                            <b-form-checkbox class="toggle-with-review"
                                             switch
                                             size="lg"
                                             v-model="isOnlyWithReviews">
                                Only with reviews
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <organizer-item class="col-12 px-2 mb-3"
                                    v-for="organizer in organizersFiltered"
                                    v-bind:key="organizer._id"
                                    v-bind:organizer="organizer">
                    </organizer-item>
                </div>
            </div>
            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view></router-view>
            </div>
        </div>
    </section>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
    name: "Organizers",
    metaInfo () {
        return {
            "title": "Organizers",
        };
    },
    components: {
        BFormCheckbox,
        RegionSelect: () => import("@/components/layout/RegionSelect"),
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
        SearchBar: () => import("@/components/layout/SearchBar"),
        OrganizerItem: () => import("@/components/Organizers/OrganizerItem"),
    },
    data () {
        return {
            keywords: "",
            isOnlyWithReviews: false,
        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        organizers () {
            return this.$store.getters["users/itemsByRole"]("organizer").reverse();
        },
        organizersFiltered () {
            return this.isOnlyWithReviews ?
                this.organizers.filter(({ profile: { organizerResponseCount } }) => organizerResponseCount) :
                this.organizers;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2;
}
</style>

<style lang="scss">
.toggle-with-review {
    .custom-control-label {
        font-size: 1rem !important;
        line-height: 1.1 !important;
        width: 90px;
    }
}
</style>
